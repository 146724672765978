import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "empty-hand-forms"
    }}>{`Empty Hand Forms`}</h2>
    <ul>
      <li parentName="ul"><Link to="/forms/essential" mdxType="Link">Essential Form</Link></li>
      <li parentName="ul"><Link to="/forms/tai-chi-kung" mdxType="Link">Tai Chi Kung</Link> - 10 Movement Form</li>
    </ul>
    <h2 {...{
      "id": "weapon-forms"
    }}>{`Weapon Forms`}</h2>
    <ul>
      <li parentName="ul"><Link to="/forms/sword" mdxType="Link">Sword Form</Link></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      